import React, { useContext, useEffect, useState } from 'react'

import { CartContext } from '../context/CartContext'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import SEO from "../components/SEO/SEO"
import Cart from '../components/Cart/Cart'
import InfoCard from '../components/InfoCard/InfoCard'

import { GTViewCart } from '../helpers/gtagEvents'

const CartPage = ({ location }) => {

	const { cart } = useContext( CartContext );
	const [ hasMounted, setHasMounted ] = useState(false);

	useEffect(() => {
		setHasMounted( true )
		GTViewCart( cart )
	}, [cart])

	if ( !hasMounted ) {
		return null;
	}

	return (
		<>
			<SEO title="Cart" />
			<Breadcrumb pathname={ location.pathname } />

			<div className="wrapper">
				{ cart && cart.length > 0 ? (
					<Cart />
				) : (
					<div className="cart">
						<InfoCard title="Your bag is empty" description="Add items to your bag and sign in to get shopping!" infoIcon={ false } />
					</div>
				)}
			</div>
		</>
	)
}

export default CartPage;