import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import Shipping from "../Shipping/Shipping"

import { formatPrice } from '../../helpers/currency';
import { cartSubTotal } from '../../helpers/cart';

import {
	RiSubtractLine,
	RiAddLine,
	RiDeleteBinLine
} from 'react-icons/ri'

export default function Cart() {

	const { cart, addToCart, removeFromCart } = useContext( CartContext );

	const incrementQuantity = ( increment, product ) => {
		addToCart( product, increment, false)
	}

	return (
		<div className="cart">

			<div className="cart__products-container">

				<h2 className="cart__header">My Bag</h2>

				{ cart.map( product => (

					<div className="cart__product" key={ product.id }>
						<Link className="cart__product-link" to={`/products/${ product.slug }`}> 
							<GatsbyImage className="cart__product-image" image={ product.images[0].localFile.childImageSharp.gatsbyImageData } alt={ product.name } />
						</Link>
						<div className="cart__product-info">
							
							<div className="cart__product-price">{ formatPrice( product.price ) }</div>
							<Link className="cart__product-link" to={`/products/${ product.slug }`}>
								<div className="cart__product-name">{ product.name }</div>
							</Link>
							
							<div className="cart__inputs-container">
								<span className="cart__inputs-label">Quantity:</span>
								<div className="cart__quantity-container">
									<button
										className={`cart__quantity-trigger ${ product.quantity === 1 ? 'disabled' : '' }`}
										onClick={ () => incrementQuantity( -1, product ) }
										aria-label="Decrease quantity"
									>
										<RiSubtractLine className="cart__quantity-icon" />
									</button>
									<div
										type="number"
										className="cart__quantity-input">
										{ product.quantity }
									</div>
									<button
										className={`cart__quantity-trigger ${ product.quantity === product.quantityInStock ? 'disabled' : ''}`}
										onClick={ () => incrementQuantity( 1, product ) }
										aria-label="Increase quantity"
									>
										<RiAddLine className="cart__quantity-icon" />
									</button>
								</div>
							</div>
							
							<RiDeleteBinLine className="cart__product-remove" onClick={ () => removeFromCart( product ) } />

						</div>
					</div>
				) ) }

			</div>

			<div className="cart__price-container">
				<h2 className="cart__header">Total</h2>
				<div className="cart__subtotal">
					Sub total:
					<span className="cart__subtotal-price">{ formatPrice( cartSubTotal( cart ) ) }</span>
				</div>
				<div className="cart__delivery">Estimate Delivery:</div>

				<Shipping />

				<Link to="/checkout" className="button button--no-margin">
					Checkout
				</Link>
			</div>

		</div>
	)
}
